import mapPin from '../img/map-pin.svg'
import iconYoutube from '../img/icon-youtube.svg'

/**
 * Export all the theme images
 */

const themeMedia = () => {
    newImage(mapPin)
    newImage(iconYoutube)
}

/**
 * Compile image from src folder
 * 
 * @param Image source 
 */

const newImage = (source) => {
    const newImage = new Image()
    newImage.src = source
}

export default themeMedia
import themeMedia from './themeMedia'
themeMedia()

Promise.all(
    [
        import('./headerHeroNav'),
    ]
).then(
    (
        [
            headerHeroNav,

        ]
    ) => {
        headerHeroNav.default()
    }
)